<template>
    <div>
        <b-row>
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('code')">
                    <div class="label-as-input">{{ isNotNullValue(formData.id) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="9">
                <b-form-group :label="$t('subject')">
                    <b-input-group prepend="TR" class="mb-2">
                        <div class="label-as-input">
                            {{ isNotNullValue(formData.subject) }}
                        </div>
                        </b-input-group>
                    <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.subject_en) }}</div></b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
                <b-form-group :label="$t('explanation')">
                    <b-input-group prepend="TR" class="mb-2"><div class="label-as-input" v-html="isNotNullValue(formData.explanation)"></div></b-input-group>
                        <b-input-group prepend="EN"><div class="label-as-input" v-html="isNotNullValue(formData.explanation_en)"></div></b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                    <b-form-group :label="$t('start_date')">
                        <b-input-group ><div class="label-as-input">{{ isNotNullValue(formData.start_date) }}</div></b-input-group>
                    </b-form-group>
            </b-col>
            <b-col>

                    <b-form-group :label="$t('end_date')">
                        <b-input-group><div class="label-as-input">{{ isNotNullValue(formData.end_date) }}</div></b-input-group>
                    </b-form-group>
            </b-col>
            <b-col>
                    <b-form-group :label="$t('roles')">
                        <b-input-group >
                            <div class="label-as-input" v-for="role in showRoles" :key="role.id">{{ getLocaleText(role,'explanation') }}</div>
                        </b-input-group>
                    </b-form-group>
            </b-col>

            <b-col>
                    <b-form-group :label="$t('program_level')">
                        <b-input-group ><div class="label-as-input" v-for="level in showPrograms">{{ getLocaleText(level,'name') }}</div></b-input-group>
                    </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t('status')">
                    <b-input-group ><div class="label-as-input">{{ isNotNullValue(formData.status==='a'? 'aktif' :'pasif') }}</div></b-input-group>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t('file')">
                    <b-input-group ><div class="label-as-input">{{ isNotNullValue(fileName) }}</div></b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
                <ValidationProvider name="student_program_ids" rules="" v-slot="{valid, errors}">
                    <b-form-group :label="$t('student_program_id')">
                        <lined-textarea v-model="formData.student_program_ids"
                                        :nowrap="false"
                                        :disabled="true"
                                        :styles="{ height: '150px', resize: 'both' }"
                                        :validateError="errors[0]">
                        </lined-textarea>
                        <div class="invalid-feedback d-block" v-if="errors[0]"
                             v-html="errors[0]"></div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    // Services
    import AnnouncementsService from '@/services/AnnouncementsService';
    import RoleService from "@/services/RoleService";
    import ParameterService from "@/services/ParameterService";
    import qs from "qs";
    import LinedTextarea from "@/components/elements/LinedTextarea.vue";

    export default {
        components: {
            LinedTextarea

        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formData: {},
                showRoles:[],
                showPrograms:[],
                showStatus:null,
                fileName:null
            }
        },
        async created() {
            await this.get(this.formId)
             this.getPrograms()
        },
        methods: {
            get(id) {
                AnnouncementsService.get(id)
                              .then(response => {
                                  this.formData = response.data.data;
                                  this.formData.student_program_ids=response.data.data.student_program_ids[0]
                                  this.fileName=response.data.data.file.file_name
                                  this.showStatus=this.formData.status
                                  this.getRoles(this.formData.roles)
                                  this.getPrograms(this.formData.program_level)
                              })
                              .catch(e => {
                                  this.showErrors(e)
                              })

            },
            getRoles(value) {
                const selectedRoles = []
                value.forEach(item=>{
                    selectedRoles.push(parseInt(item))
                })
                const config = {
                    params: {
                        limit: -1
                    }
                };
                RoleService.getAll(config)
                    .then(response => {
                        const data = response.data.data;
                        data.forEach(itm=>{
                              if (selectedRoles.includes(itm.id)){
                                  this.showRoles.push(itm)
                              }
                        })

                    })
                    .catch(e => {
                        this.showErrors(e);
                    });
            },
            getPrograms(value){
                let selectedPrograms=value
                const config = {
                    params: {
                        filter: {
                            parameter_code: 'program_levels',
                        },
                        sort: 'name',
                        limit: -1,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
                ParameterService.getAllItems(config)
                    .then((response) => {
                        let data=response.data.data
                        data.forEach(itm=>{
                            if (selectedPrograms.includes(itm.code)){
                               this.showPrograms.push(itm)
                            }
                        })
                    }).catch(e=>{
                        this.showErrors(e)
                })

            }

        }
    }
</script>
