<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('sending_sms_student_search')" @filter-div-status="datatable.filterStatus = $event"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('sending_sms_student_search')"
                              @filter-div-status="datatable.filterStatus = $event"/>
            </template>
            <datatable-filter v-show="datatable.filterStatus" :filterButtonClass="'mt-1'" @filter="filter"
                              @filterClear="filterClearAndGetRow"
                              :exportExcel="checkPermission('studentprogram_excelexport')">
                <div>
                    <b-tabs content-class="py-5 position-relative" class="line-tabs">
                        <div class="tabs-bottom-line"></div>
                        <!-- Tab 1: Kişi Bilgileri -->
                        <b-tab active>
                            <template #title>
                                <span>{{ $t('personal_information') }}</span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('student_number')">
                                        <multi-text v-model="datatable.queryParams.filter.student_number"
                                            :only-number-and-enter="true"
                                        />
                                        <!--<lined-textarea v-model="datatable.queryParams.filter.student_number"
                                                        :nowrap="false"
                                                        :disabled="false"
                                                        :styles="{ height: '10em', resize: 'both' }"
                                        >
                                        </lined-textarea>-->
                                        <!--                                        <b-form-input type="number"-->
                                        <!--                                                      v-model="datatable.queryParams.filter.student_number"-->
                                        <!--                                                      v-check-min-max-value></b-form-input>-->
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('name')">
                                        <b-form-input v-model="datatable.queryParams.filter.name"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('surname')">
                                        <b-form-input v-model="datatable.queryParams.filter.surname"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('foreign_student_q')">
                                        <parameter-selectbox
                                            code="student_groups"
                                            v-model="datatable.queryParams.filter.student_group"
                                        />

                                        <!--                                        <YesNoSelectbox >-->

                                        <!--                                        </YesNoSelectbox>-->
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('settlement_scholarship')">
                                        <b-input-group>
                                            <template #append>
                                                <b-input-group-text class="font-weight-bolder h-40">%
                                                </b-input-group-text>
                                            </template>
                                            <b-form-input type="number"
                                                          v-model="datatable.queryParams.filter.scholarship_rate"
                                                          :min="0" :max="100"
                                                          v-check-min-max-value>
                                            </b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>


                            </b-row>
                        </b-tab>


                        <!-- Tab 4: Dönem / Kayıt Bilgileri -->
                        <b-tab>
                            <template #title>
                                <span> {{ $t('semester_registration_information') }}</span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4">
                                    <b-form-group :label="$t('registration_academic_year')">
                                        <academic-years-selectbox
                                            v-model="datatable.queryParams.filter.registration_academic_year"></academic-years-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('registration_semester')">
                                        <parameter-selectbox
                                            v-model="datatable.queryParams.filter.registration_semester" code="semester"
                                            sort="code"></parameter-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('registration_type')">
                                        <parameter-selectbox code="registration_types" size="sm"
                                                             v-model="datatable.queryParams.filter.registration_type"
                                        ></parameter-selectbox>
                                    </b-form-group>

                                    <b-form-group :label="$t('semester_status')">
                                        <parameter-selectbox
                                            v-model="datatable.queryParams.filter.active_semester_status"
                                            code="semester_statuses"></parameter-selectbox>
                                    </b-form-group>
<!--                                    <b-form-group :label="$t('registration_status')">-->
<!--                                        <yes-no-selectbox-->
<!--                                            v-model="datatable.queryParams.filter.registration_status"></yes-no-selectbox>-->
<!--                                    </b-form-group>-->


                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-form-group :label="$t('faculty')">
                                        <faculty-selectbox
                                            v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                                    </b-form-group>

                                    <b-form-group :label="$t('department')">
                                        <department-selectbox
                                            :faculty_code="datatable.queryParams.filter.faculty_code"
                                            v-model="datatable.queryParams.filter.department_code"></department-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox
                                            :is_department_code_required="true"
                                            :faculty_code="datatable.queryParams.filter.faculty_code"
                                            :department_code="datatable.queryParams.filter.department_code"
                                            v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                                    </b-form-group>

                                    <b-form-group :label="$t('mode_of_study')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.mode_of_study"
                                                             code="mode_of_studies"></parameter-selectbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-form-group :label="$t('number_of_semesters')">
                                        <period-selectbox
                                            v-model="datatable.queryParams.filter.number_of_semesters"></period-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('campus')">
                                        <campus-selectbox v-model="datatable.queryParams.filter.campus_id"/>
                                    </b-form-group>
                                    <b-form-group :label="$t('level')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.program_level"
                                                             :multiple="true"
                                                             code="program_levels"></parameter-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('student_status')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.student_status"
                                                             code="student_statuses"></parameter-selectbox>
                                    </b-form-group>

                                </b-col>
                                <b-col cols="12" md="4">
                                </b-col>


                            </b-row>
                        </b-tab>

                        <!-- Tab 5: Ödeme ve Burs Bilgileri -->
                        <b-tab>
                            <template #title>
                                <span>{{ $t('graduation_information') }}</span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4" lg="3">
                                    <b-form-group :label="$t('graduation_academic_year')">
                                        <academic-years-selectbox
                                            v-model="datatable.queryParams.filter.graduation_academic_year"></academic-years-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('graduation_semester')">
                                        <parameter-selectbox v-model="datatable.queryParams.filter.graduation_semester"
                                                             code="semester" sort="code"></parameter-selectbox>
                                    </b-form-group>
                                    <b-form-group :label="$t('graduate_decision_date')"
                                                  class="position-relative">
                                        <v-date-picker
                                            v-model="datatable.queryParams.filter.birthdate"
                                            :locale="$i18n.locale"
                                            is-expanded
                                            :masks="masks"
                                            :max-date="new Date()"
                                            :popover="{ 'visibility': 'click' }">

                                            <template
                                                v-slot="{ inputValue, inputEvents }">
                                                <b-input-group>
                                                    <b-form-input
                                                        :readonly="true"
                                                        :value="inputValue"
                                                        v-on="inputEvents"
                                                    ></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button
                                                            variant="outline-secondary"
                                                            class="btn-40"
                                                            disabled
                                                        >
                                                            <i class="ri-calendar-line"></i
                                                            ></b-button>
                                                    </b-input-group-append>

                                                </b-input-group>
                                            </template>
                                        </v-date-picker>
                                    </b-form-group>
                                </b-col>

                            </b-row>
                        </b-tab>
                    </b-tabs>
                </div>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                       @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"/>

            <CommonModal ref="showModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()"
            >
                <template v-slot:CommonModalTitle>
                    {{ $t('send_sms').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="form">

                        <b-col cols="12" md="12" lg="12" xl="12">
                            <ValidationProvider name="originator" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('originator')">
                                    <parameter-selectbox code="originators_code" v-model="form.originator"
                                                         :validate-error="errors[0]">
                                    </parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="description" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('explanation')">
                                    <b-form-input v-model="form.description"></b-form-input>
                                </b-form-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                            <ValidationProvider name="message" :rules="form.message_en ?'':'required'" v-slot="{valid, errors}">
                                <b-input-group prepend="TR" class="mb-2">
                                    <b-form-textarea
                                        id="textarea-rows"
                                        v-model="form.message"
                                        rows="10"
                                    />
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                            <ValidationProvider name="message_en" :rules="form.message?'':'required'" v-slot="{valid, errors}">

                                <b-input-group prepend="EN">
                                    <b-form-textarea
                                        id="textarea-rows"
                                        v-model="form.message_en"
                                        rows="10"
                                    />
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                            <b-button variant="primary" class="mt-4" @click="sendSms()">
                                {{ $t('send_sms') }}
                            </b-button>

                        </b-col>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <b-button class="mt-4" @click="openModal" :disabled="showButton">
                {{ $t('continue') }}
            </b-button>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';

// Components
import AcademicYearsSelectbox from '../../../components/interactive-fields/AcademicYearsSelectbox';
import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox';
import CountrySelectbox from '@/components/interactive-fields/CountrySelectbox';
import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox';
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
import GenderSelectbox from '@/components/interactive-fields/GenderSelectbox';
import LanguageSelectbox from '@/components/interactive-fields/LanguageSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import PeriodSelectbox from '../../../components/interactive-fields/PeriodSelectbox';
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
import SemestersSelectbox from '../../../components/interactive-fields/SemestersSelectbox';
import ThesisSelectbox from '@/components/interactive-fields/ThesisSelectbox';
import YesNoSelectbox from '@/components/interactive-fields/YesNoSelectbox';
import CommonModal from '@/components/elements/CommonModal';
import GnoRange from '@/components/elements/GnoRange';
import MultiText from '@/components/interactive-fields/MultiText';

import ShowForm from "../../announcement/pages/ShowForm.vue";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import qs from "qs";
import sendSmsServices from "@/services/sendSmsServices";


export default {
    components: {
        LinedTextarea,
        ShowForm,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,

        AcademicYearsSelectbox,
        CampusSelectbox,
        CountrySelectbox,
        DepartmentSelectbox,
        FacultySelectbox,
        GenderSelectbox,
        LanguageSelectbox,
        ParameterSelectbox,
        PeriodSelectbox,
        ProgramSelectbox,
        SemestersSelectbox,
        ThesisSelectbox,
        YesNoSelectbox,
        GnoRange,
        MultiText,

        CommonModal
    },
    metaInfo() {
        return {
            title: this.$t('sending_sms_student_search')
        }
    },
    data() {
        return {
            form: {},
            formId: null,
            formProcess: null,
            showButton: true,

            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.$t('name'),
                        field: 'name',
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',

                    },
                    {
                        label: this.$t('faculty'),
                        field: 'faculty_name',

                    },
                    {
                        label: this.$t('program'),
                        field: 'program',

                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            masks: {
                input: "DD-MM-YYYY",
            },
        }
    },
    watch: {
        'datatable.rows'(value) {
            if (value != null) {
                value.length > 0 ? this.showButton = false : this.showButton = true

            } else {
                this.showButton = true
            }
        }
    },
    methods: {
        formClear() {
            this.filterClear();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {}
            this.getRows()
        },
        filterClearAndGetRow() {
            this.filterClear();
            this.datatable.rows = null
            this.datatable.total = null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            sendSmsServices.getStudentSearch(config)
                .then(response => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total

                    this.datatable.isLoading = false

                }).catch(err => {
                this.showErrors(err)
            }).finally(  this.datatable.isLoading = false)
        },
        openModal() {
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
        closeModal() {
            this.form = {}
            this.formProcess = null
            this.$refs.showModal.$refs.commonModal.hide()
        },
        async sendSms() {
            const isValid = await this.$refs.form.validate()
            if (!isValid) {
                return
            }
            const formData = {
                filter: {
                    ...this.datatable.queryParams.filter
                },
                ...this.form
            }
            sendSmsServices.sendSmsStudent(formData)
                .then(response => {
                    this.$toast.success(this.$t("api." + response.data.message))
                    this.closeModal()
                }).catch(err => {
                this.showErrors(err)
            })

        }

    }
}
</script>

